:root {
    --moving-text-color: #000;
    --primary-color: #20448E;
    --secondary-color: #EF3C0F;
    --white-color: #fff;
    --black-color: #000;
    --gray-color: #ccc;
    --gray2-color: #E0E0E0;
    --gray3-color: #F8F8F8;
    --section-color: #F5F5F5;
    --light-pink: #FFCEC2;
}

.hamburger {
    height: 30px;
    width: 30px;
    cursor: pointer;
}

@media (min-width: 1400px) {}

@media (max-width: 1200px) {
    .arrow-vector {
        width: 60px;
    }

    .powder-images {
        width: 460px;
        height: auto;
        object-fit: contain;
    }


    .powder-images-child {
        width: 180px;
        height: auto;
        object-fit: auto;
    }

    .powder-image-overlay-children h5 {
        font-size: 18px;
    }
}

@media (min-width: 993px) {
    .hamburger {
        display: none;
    }

    .left-angle {
        display: none;
    }
}

@media (max-width: 992px) {

    .fotter-container {
        padding: 20px;
    }


    .footer-logo {
        height: auto;
        max-height: 60px;
    }

    .slider-container {
        display: block;
        align-items: normal;
    }

    .mobile-header {
        display: block;
    }

    .main-header-container {
        display: none;
    }

    .active {
        background: none;
        color: var(--secondary-color) !important;
        font-weight: bold;
    }


    .contact-form {
        width: 100%;
    }

    .contact-form-left {
        display: none;
    }

    .contact-info-box {
        padding: 20px 20px
    }

    .info-box {
        width: auto;
    }

    .arrow-vector {
        width: 40px;
    }

    .powder-images {
        width: 360px;
        height: auto;
        object-fit: contain;
    }


    .powder-images-child {
        width: 140px;
        height: auto;
        object-fit: auto;
    }

    .powder-image-overlay-children h5 {
        font-size: 14px;
    }
}

/* 992px end here  */

@media (max-width: 768px) {
    .table-container {
        margin-top: 0px;
    }

    .packing-category-heading {
        font-size: 16px;
    }


    .contact-sub-title {
        font-size: 14px;
    }

    .contact-title {
        font-size: 16px;
    }


    .contact-icon-box {
        border-radius: 100px;
        padding: 25px 20px;
        height: 100px;
        width: 100px;
    }

    .contact-icon {
        height: 45px;
        width: 45px;
    }



    .packing-description {
        font-size: 14px;
    }


    .home_page_btn {

        font-size: 12px;
        padding: 0px 10px;
        height: 40px;

    }

    .rfm-marquee-container {
        padding: 5px 0px;
    }


    .marquee-dot {
        width: 15px;
        height: 15px;

    }


    .rfm-child {
        font-size: 14px;
    }


    .main-logo {
        height: 60px;
    }

    .common-spacing2 {
        padding: 20px 20px;
    }

    .common-spacing {
        padding: 20px 20px;
    }

    .packing-inner-container-left {
        padding-left: 0px;
    }

    .packing-description-2 {
        padding-right: 0px;
    }

    .machines-image {
        width: 100%;
        height: 300px;
        object-fit: contain;
    }

    .machines-image-padding-left {
        padding-left: 0px;
    }

    .machines-image-padding-right {
        padding-right: 0px;
    }



    .contact-banner-inner h1 {
        font-size: 28px;
        font-weight: bolder;
    }

    .contact-banner-inner br {
        display: none;
    }


    .contact-banner-inner a {
        font-size: 18px;
    }



    .about-contianer {
        padding: 20px;
    }


    .section-title {
        font-size: 18px !important;
        font-weight: 900 !important;
    }



    .section-description {
        /* margin-bottom: 40px; */
        font-size: 16px;
        line-height: 1.6;
        font-weight: 500;
    }

    .experience-image {
        margin-left: 0px;
        width: 300px;
    }


    .experience-container {
        padding-top: 20px;
        padding-bottom: 20px;
        /* border-top: 2px solid var(--primary-color); */
    }


    .home-about-image {
        height: 400px;
        background-size: contain;
    }

    .home-about-image {
        display: none !important;
    }

    .main-image-container {
        display: none;
    }

    .revo-machine-heading {
        font-size: 30px;
    }

    .custom-navigation {
        display: none;
    }

    .common-spacing-side {
        padding: 0px 20px;
    }

    .quaity-text {
        font-size: 16px !important;
    }

    .quality-line {
        display: none;
    }


    .revo-machine-description {
        font-size: 14px;
    }

    .section-description {
        font-size: 1p4x;
    }


    .contact-info-section {
        background-color: var(--primary-color);
        margin-top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: auto;
        position: relative;
    }

    .contact-info-section-child {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .custom-accordion-header {
        font-size: 16px;
    }

    .custom-accordion-body {
        font-size: 14px;
    }



    .home_section_heading h1 {
        font-size: 14px;
    }

    .home_section_heading h2 {
        font-size: 18px;
        margin: 6px 0px 0px 0px;
    }



    .powder-images {

        height: 300px;
        width: 450px
    }


    .powder-image-overlay h2 {
        font-size: 16px;
    }

    .powder-image-overlay-children h5 {
        font-size: 12px;
    }




    .powder-images-child {
        height: 120px;
        width: 180px
    }


    .lob_arrow {
        position: relative;
        top: -10px;
        right: 10px;
        height: 40px;

    }

    .contact-banner-content-left {
        display: none;
    }


    .contact-banner-inner {
        padding-left: 10px;
    }

    .table-responsive table {
        min-width: 992px;
        width: 100%;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive td {
        padding: 5px;
    }

    .table-responsive th {
        padding: 5px;
    }

    .table-container {
        overflow-x: auto;
    }

    .table thead th,
    .table tbody td {
        max-width: 120px;
        width: 100%;
    }


    .contact-form {
        width: 100%;
        padding: 10px 10px;
        box-shadow: 0px 10px 10px -5px rgba(71, 71, 71, 0.1);
    }


    .contact-form-inner {
        padding: 0px 40px 0px 40px;
    }

    .powder-packing-container {
        align-items: center !important;
        margin-top: 30px;
    }

    .powder-packing-container-first {
        align-items: center !important;
        margin-top: 0px;
    }

    .slider-container {
        display: block;
        /* align-items: center; */
    }

    .arrow-vector {
        width: 25px;
    }

    .powder-images {
        width: 240px;
        height: auto;
        object-fit: contain;
    }


    .powder-images-child {
        width: 90px;
        height: auto;
        object-fit: auto;
    }

    .footer-angle {
        display: block;
    }

    .footer h5 {
        font-size: 16px;
        margin-bottom: 0px;
        font-weight: bold;
    }

    /* Rotate the angle icon */
    .footer-angle.rotate {
        transform: rotate(90deg);
        transition: transform 0.3s ease;
    }

    /* Default hidden state with height set to 0 and overflow hidden */
    .list-unstyled {
        max-height: 0;
        overflow: hidden;
        margin-top: 0;
        margin-left: 0;
        padding-left: 0;
        transition: max-height 0.5s ease, margin-top 0.5s ease, margin-left 0.5s ease;
    }

    /* Show state with height auto, additional margin, and padding */
    .list-unstyled.show {
        max-height: 500px;
        /* Set this to a value that accommodates the content */
        margin-top: 10px;
        margin-left: 15px;
        padding-left: 10px;
        transition: max-height 0.5s ease, margin-top 0.5s ease, margin-left 0.5s ease;
    }

    .back-to-top {
        position: absolute;
        bottom: 40px;
        right: 30px;
        background-color: var(--white-color);
        color: var(--primary-color);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        text-align: center;
        display: flex;
        line-height: 20px;
        text-decoration: none;
        transition: background-color 0.3s;
        cursor: pointer;
    }
}

/* 798px end here  */

@media (max-width: 576px) {

    .slider-container {
        display: block;

    }
}

/* 579px end here  */

@media (min-width: 320px) and (max-width:430px) {
    .table-container {
        margin-top: 0px;
    }

    .packing-category-heading {
        font-size: 14px;
    }


    .contact-icon-box {
        border-radius: 100px;
        padding: 25px 20px;
        height: 100px;
        width: 100px;
    }

    .contact-icon {
        height: 45px;
        width: 45px;
    }


    .contact-sub-title {
        font-size: 12px;
    }

    .contact-title {
        font-size: 14px;
    }

    .packing-description {
        font-size: 12px;
    }

    /* .main-logo {
        height: 40px;
    } */


    .common-margin-top {
        margin-top: 20px;
    }

    .common-margin-bottom {
        margin-bottom: 20px;
    }


    .common-spacing2 {
        padding: 20px 20px;
    }

    .common-spacing {
        padding: 20px 20px;
    }

    .packing-inner-container-left {
        padding-left: 0px;
    }

    .packing-description-2 {
        padding-right: 0px;
    }

    .contact-banner-content-left {
        display: none;
    }

    .home_page_btn {

        font-size: 10px;
        padding: 0px 10px;
        height: 40px;

    }

    .social-links {
        margin-left: 0px !important;
    }

    .main-image-container {
        display: none;
    }

    .home-about-image {
        display: none !important;
    }


    .machines-image {
        width: 100%;
        height: 200px;
        object-fit: contain;
    }

    .rfm-marquee-container {
        padding: 5px 0px;
    }


    .marquee-dot {
        width: 15px;
        height: 15px;

    }


    .rfm-child {
        font-size: 12px;
    }




    .about-contianer {
        padding: 20px;
    }


    .section-title {
        font-size: 18px !important;
        font-weight: 900 !important;
    }



    .section-description {
        /* margin-bottom: 40px; */
        font-size: 16px;
        line-height: 1.6;
        font-weight: 500;
    }

    .experience-image {
        margin-left: 0px;
        padding: 0px 10px;
        width: 300px;
    }

    .powder-images {

        height: 160px;
        width: 250px
    }

    .powder-images-child {
        height: 80px;
        width: 100px;

    }

    .contact-banner-inner {
        padding-left: 10px;
    }


    .contact-banner-inner h1 {
        font-size: 22px;
        font-weight: bolder;
    }

    .contact-banner-inner br {
        display: none;
    }


    .contact-banner-inner a {
        font-size: 14px;
    }


    .custom-horizontal-line {
        display: none;
    }

    .custom-horizontal-line_2 {
        display: none;
    }



    .home_section_heading h1 {
        font-size: 12px;
    }

    .home_section_heading h2 {
        font-size: 16px;
        margin: 4px 0px 0px 0px;
    }

    /* 
    .machines-image {
        height: 100%;
        width: 100%;
    } */

    .card-image {
        /* height: 110px;
        width: 110px; */
        margin-top: 15px;
    }

    .hero_section {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        overflow: hidden;
    }

    .video-background {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }




    .hero_section .hero_section_inner h1 {
        font-size: 40px;
    }



    .packing_sample {
        font-size: 14px;

    }


    .lob_arrow {
        position: relative;
        top: -10px;
        right: 10px;
        height: 30px;

    }



    .revo-machine-heading {
        font-size: 25px;

    }

    .quality-line {
        display: none;
    }

    .quaity-text {
        font-size: 14px !important;
    }

    .revo-machine-description {
        font-size: 12px;
    }

    .section-description {
        font-size: 12px;
    }


    .contact-info-section {
        background-color: var(--primary-color);
        margin-top: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: auto;
        position: relative;
    }

    .contact-info-section-child {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .custom-accordion-header {
        font-size: 14px;
    }

    .custom-accordion-body {
        font-size: 12px;
    }


    .common-spacing-side {
        padding: 0px 20px;
    }

    .custom-navigation {
        display: none;
    }


    .contact-form {
        width: 100%;
        padding: 10px 10px;
        box-shadow: 0px 10px 10px -5px rgba(71, 71, 71, 0.1);
    }


    .contact-form-inner {
        padding: 0px 20px 0px 20px;
    }

    .category_card {
        margin-bottom: 15px;
    }



    .arrow-vector {
        width: 20px;
    }

    .powder-images {
        width: 170px;
        height: auto;
        object-fit: contain;
    }

    .powder-images-child {
        height: 40px;
        width: 40px;
        object-fit: contain;
    }

    .powder-images-child {
        width: 60px;
        height: auto;
        object-fit: auto;
    }

    .powder-image-overlay-children h5 {
        font-size: 10px;
    }

    .copyright span {
        font-size: 12px;
    }

    .card-title {
        font-size: 24px;
    }

}

/* 360px end here  */



@media (max-width: 450px) {
    .mobile-drawer {
        width: 100%;
    }
}