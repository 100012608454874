@font-face {
  font-family: "lato";
  src: local("lato"),
    url("./fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"),
    url("./fonts/Lato-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New', monospace;
}