:root {
  --moving-text-color: #000;
  --primary-color: #20448E;
  --secondary-color: #EF3C0F;
  --white-color: #fff;
  --black-color: #000;
  --gray-color: #ccc;
  --gray2-color: #E0E0E0;
  --gray3-color: #F8F8F8;
  --section-color: #F5F5F5;
  --light-pink: #FFCEC2;
}

.common-spacing {
  padding: 70px 60px;
}

.common-spacing2 {
  padding: 50px 50px;
}

.common-margin-top {
  margin-top: 60px;
}

.common-margin-bottom {
  margin-bottom: 60px;
}

.common-spacing-side {
  padding: 0px 50px;
}

.common-spacing-bottom {
  padding-bottom: 30px;
}

.common-spacing-top {
  padding-top: 30px;
}

.common-spacing-side2 {
  padding: 0px 50px;
}

.max-width {
  max-width: 1440px;
}


.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.lato-thin-italic {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.lato-light-italic {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.lato-regular-italic {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.lato-bold-italic {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.lato-black-italic {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
}


.custom-horizontal-line {
  height: 5px;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), #FF4500);
  border: none;
  margin: 0;
  flex: 1
}




.custom-horizontal-line-3 {
  height: 5px;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), var(--primary-color));
  border: none;
  margin: 0;
  flex: 1
}

.custom-horizontal-line_2 {
  height: 5px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #FF4500);
  border: none;
  margin: 0;
  flex: 1
}

.custom-horizontal-line-container {
  max-width: 1440px;
  width: 100%;
  float: right;
}


.slider-container {
  display: flex;
  align-items: center;
}

.text-primary {
  color: var(--white-color) !important;
}

.letter-spacing {
  letter-spacing: 1px;
}

.header-letter-spacing {
  letter-spacing: 0.5px;
}

.word-spacing {
  word-spacing: 0px;
}

.line-height {
  line-height: 1.5;
}

.section-color {
  background-color: var(--white-color);
}

.marguee-container {
  padding: 10px;
  background-color: var(--secondary-color);
  color: var(--white-color);
  /* text-transform: lowercase; */
  z-index: 2;
}

.marquee-dot {
  width: 20px;
  height: 20px;
  background-color: var(--white-color);
  border-radius: 100px;
  margin-left: 10px;
  margin-right: 10px;
}




.header-social-box {
  width: 70px;
  height: 100px;
  background-color: var(--primary-color);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  transform: rotateY(10deg);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: -15px;
  /* Initial margin for the upward position */
  margin-left: 10px;
  margin-right: 10px;
  transition: margin-top 0.3s ease;
  z-index: 1;
}

.header-social-box:hover {
  margin-top: 10px;
  /* Move down 10px on hover */
  transition: margin-top 0.3s ease;
}



.header-social-box:hover {
  margin-top: -5px;
}

.main-banner {
  position: relative;
  max-width: 1440px;

}

.main-image-container {
  position: absolute;
  right: -60px;
  top: 50px;
}



.main-image {
  height: 620px;
  background-size: contain;
}

.social-icon {
  color: var(--white-color);
  width: 30px;
  height: 30px;
  position: absolute;
}

.main-logo {
  height: 110px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.divider {
  width: 100%;
  background-color: var(--primary-color);
  height: 1.5px;
}

/* header start */
.main-header {
  background-color: var(--white-color);
}

.main-header .nav-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: auto;
}

.main-header .nav-menu li {
  padding: 10px 3px 10px 3px;
}

.main-header .nav-menu li a {
  margin-left: 2px;
  margin-right: 2px;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  color: #20448E;
  font-size: 14px;
  padding: 10px 3px 10px 3px;
  font-weight: 600;
  font-style: normal;
}

.main-header .nav-menu li:last-child {
  margin-right: 0;
}

._dropdown-menu {
  max-height: 0;
  display: none;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  list-style: none;
  padding: 0;
  margin-top: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: max-height 0.3s ease;
}

.dropdown-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

._dropdown-menu ul li {
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 500;
  list-style: none;
  cursor: pointer;
  padding: 0px !important;
}

.menu-angle {
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-color)
}

.nav-menu li:hover ._dropdown-menu {
  display: block;
  opacity: 1;
  max-height: 500px;
  align-items: center;
}

.active {
  background-color: var(--primary-color);
  color: var(--white-color) !important;
}

.layout-padding {
  padding-left: 10px;
  padding-right: 10px;
}

/* header end */

/* contact screen */
.contact-us {
  background-image: url('./assets/images/contact-overlay.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 255px;
  display: flex;
  position: relative;
  color: white;
  background-repeat: no-repeat;
}

.contact-banner-content {
  display: flex;
  align-items: center;
  height: 100%;
}

.contact-banner-inner {
  padding-left: 50px;
}

.contact-banner-inner h1 {
  font-size: 40px;
  font-weight: bolder;
}

.contact-banner-inner a {
  font-size: 22px;
  padding-left: 15px;
}

.contact-line {
  padding: 2px;
  /* flex: 1; */
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: var(--white-color);
}

.contact-banner-content-left {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}

.contact-banner-inner-left {
  padding-right: 30px;
}


.btn_1 {
  /* width: 190px; */
  height: 70px;
  border-radius: 10px;
  padding: 10px;
  background-color: var(--white-color);
  align-items: center;
  justify-content: center;
  display: flex;
}

.btn_1 a {
  font-size: 16px;
  font-weight: bold;
  color: var(--black-color) !important;
  cursor: pointer;
}

.btn_1 a:last-of-type {
  color: var(--secondary-color) !important;
}

.contact-form {
  width: 65%;
  padding: 60px 50px 60px 50px;
  box-shadow: 0px 10px 10px -5px rgba(71, 71, 71, 0.1);
}

.contact-form-inner {
  padding: 0px 80px 0px 80px;
}

.contact-form-left {
  width: 35%;
  background-color: var(--primary-color);
}

.get_in {
  font-size: 42px;
  font-weight: 900;
  color: var(--black-color);
}

.touch {
  color: var(--secondary-color);
}

.contact-form p {
  color: var(--black-color);
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 500;
}

.input-style {
  height: 50px;
  border: 2px solid var(--gray2-color);
  box-shadow: none;
}

.input-style:focus {
  border-color: var(--gray2-color);
  outline: none;
  box-shadow: none;
}

.text_area_input-style {
  border: 2px solid var(--gray2-color);
  box-shadow: none;
}

.text_area_input-style:focus {
  border-color: var(--gray2-color);
  outline: none;
  box-shadow: none;
}

.whatsapp {
  color: #3AE85B !important;
}


.youtube {
  color: var(--secondary-color) !important;
}

.facebook {
  color: #465993 !important;
}

.contact-info-box {
  padding: 70px 60px 60px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.info-box {
  align-items: center;
  justify-content: center;
  border: 2px solid var(--gray2-color);
  text-align: center;
  border-radius: 7px;
  width: 370px;
  height: 320px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: 10px;
}

.info-box:hover {
  transform: scale(1.01);
  box-shadow: 0 10px 20px 10px rgba(71, 71, 71, 0.1);
  border: none;
}

.info-box h3 {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  color: var(--secondary-color);
}

.info-box h1 {
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: 900;
  color: var(--black-color);
}

.info-box p {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 500;
  color: var(--black-color);
}

.info-box img {
  max-width: 100px;
  height: auto;
  transition: transform 0.3s ease;
}

.info-box:hover img {
  transform: scale(1.4);
}

/* contact screen end */

/* fotter start */
.footer-angle {
  display: none;
}

.footer {
  background-color: var(--primary-color);
  color: var(--white-color);
  position: relative;
}

.fotter-container {
  padding: 60px 60px 30px 60px;
}

.footer-logo {
  max-width: 200px;
  margin-bottom: 15px;
  cursor: pointer;
}

.footer p {
  margin-bottom: 15px;
}

.footer h5 {
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: bold;
}

.footer ul {
  padding-left: 0;
  list-style: none;
}

.footer ul li {
  margin-bottom: 15px;
  cursor: pointer;
}

.footer ul li a {
  color: var(--white-color);
  text-decoration: none;
  font-size: 16px;
}

.footer ul li a:hover {
  color: var(--secondary-color);
}

.footer-icon {
  margin-right: 10px;
}

.back-to-top {
  position: absolute;
  bottom: 80px;
  right: 30px;
  background-color: var(--white-color);
  color: var(--primary-color);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  text-align: center;
  display: flex;
  line-height: 20px;
  text-decoration: none;
  transition: background-color 0.3s;
  cursor: pointer;
}

.back-to-top:hover {
  background-color: var(--secondary-color);
  color: var(--white-color);
}

.copyright {
  background-color: #062970;
  padding: 5px;
}

/* fotter end */




.selected-category-container {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  padding: 0px 0px 0px 5px;

}

.selected-category-container>div {
  display: inline-block;
}

/* about us  */
.about-us {
  background-image: url('./assets/images/contact-overlay.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 255px;
  display: flex;
  position: relative;
  color: white;
  background-repeat: no-repeat;
}

.card {
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.01);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.circle {
  width: 115px;
  height: 115px;
  position: relative;
  background-image: url('./assets/images/circle.png');
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.3s ease-in-out;
}

.circle-container {
  position: relative;
  width: 115px;
  height: 115px;
}

.about-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-in-out;
}

.about-card:hover .circle {
  transform: rotate(-15deg);
}

.about-card:hover .about-img {
  transform: translate(-50%, -50%) scale(1.1);
}

.about-contianer {
  padding: 60px 50px 80px 60px;
}

.section-title {
  font-size: 32px !important;
  font-weight: 900 !important;
}

.section-title .highlight {
  color: var(--white-color);
  background-color: var(--secondary-color);
  padding: 10px;
  border-radius: 5px;
}

.section-description {
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 1.6;
  font-weight: 500;
  text-align: justify;
}

/* Card styling */
.about-card {
  border: none;
  background-color: var(--white-color);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
}

.card-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 15px;
}

.card-text {
  font-size: 18px;
}


.card-body {
  text-align: center;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.pb-4 {
  padding-bottom: 1.5rem;
}

/* experience */
.horizontal-line {
  width: '100%';
  padding: 1.5px;
  background-color: var(--primary-color);
}

.experience {
  /* margin-bottom: 10px; */
  background-color: var(--white-color);
}

.experience-container {
  padding-top: 80px;
  padding-bottom: 70px;
  /* border-top: 2px solid var(--primary-color); */
}

.experience-content {
  padding: 20px 50px 0px 0px;
  margin: 0px;
}

.experience-content h3 {
  color: var(--secondary-color) !important;
  font-size: 16px;
  font-weight: 900px;
  margin-bottom: 5px;
}

.experience-content h1 {
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 900;
  color: var(--primary-color);
}

.experience-content p {
  color: var(--black-color);
  font-size: 16px;
  line-height: 1.4;
  /* margin-bottom: 20px; */
  text-align: justify;
  margin-top: 15px;
}


.experience-image {
  margin-left: 60px;
  width: 555px;
}

.features {
  display: flex;
  flex-wrap: wrap;
}

.feature {
  width: 43px;
  height: 43px;
  color: white;
  border-radius: 5px;
  font-weight: bold;
}

.features-container span {
  margin-left: 10px;
  line-height: 1.2;
  color: var(--black-color);
  font-size: 16px;
}

.about-contact {
  padding: 60px;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .experience-image {
    order: -1;
    border-radius: 10px 10px 0 0;
  }
}


/* about us end */


/* button style start  */
.button1 {
  width: 100%;
  height: 50px;
  background-color: var(--secondary-color);
  border-radius: 8px;
  border: 2px solid var(--secondary-color);
  color: var(--white-color);
  font-size: 16px;
  font-weight: 500;
}

.button1:hover {
  background-color: var(--white-color);
  color: var(--secondary-color);
}

/* button style end */

/* HOME PAGE STYLES  */

/* hero section  */

.hero_section {
  /* background-image: url('./assets/images/Hero_Banner.png');
  background-repeat: no-repeat;
  background-size: cover; */
  position: relative;
  /* width: 100%; */
  /* height: 590px; */
  height: 100vh;
  /* background-color: rgba(176, 30, 30, 0.1); */
  overflow: hidden;
}


.video-background {
  position: absolute;
  top: 0;
  left: 0;
  /* width: 100%;
  height: 590px; */
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}


.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 590px;
  /* background: rgba(0, 0, 0, 0.8); */
  /* background: url('./assets/Home/images/Hero-Banner-Gradient.png');
  background-repeat: no-repeat;
  background-size: cover; */
  /* background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0));  */
  z-index: 0;
}

.hero_section_inner {
  z-index: 1;
}

.hero_section .hero_section_inner h1 {
  font-size: 70px;
  color: var(--white-color);
  font: url('./fonts/Lato-Bold.ttf');
  font-weight: bold;
  text-transform: uppercase;
}

.hero_section .hero_section_inner h2 {
  font-size: 22px;
  color: var(--white-color);
  font-weight: normal;
  text-transform: uppercase;
}

.hero_btn {
  font-family: url('./fonts/Lato-Regular.ttf');
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  border: 3px solid white;
  border-radius: 8px;
  padding: 10px;
  width: 182px;
  position: relative;
  overflow: hidden;
  background: transparent;
}

.hero_btn span {
  display: inline-block;
  transition: transform 0.5s ease-in-out;
}

.hero_btn:hover span {
  transform: scale(1.08);
}

.hero_btn_2 {
  color: var(--white-color);
  margin: 0;
  margin-left: 30px;
}

.hero_btn_1 {
  color: var(--primary-color);
  background-color: var(--white-color);
  margin: 0;
}

/* hero section  */

.company_intro {
  padding: 60px 0px 60px 0px;
}

.packing_sample {
  font-size: 24px;
  font-family: url('./fonts/Lato-Regular.ttf');
  color: var(--secondary-color);
}

.lob_arrow {
  position: relative;
  top: -15px;
  right: 20px;
  /* height: 55px; */
  /* width: 60px; */
}

.category_card {
  /* height: 132px;
  width: 132px; */
  border: 3.1px solid var(--primary-color);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(32, 68, 142, 0.3);
  margin-bottom: 20px;
}

.category_card:hover {
  scale: 1.05;
  transition: 0.5s ease-in-out;
}

.packing-card {
  height: 171px;
  width: 132px;
  border-radius: 8px;
  border: 2px solid var(--primary-color);
  background-repeat: no-repeat;
  background-size: cover;
}

.packing-card:hover {
  scale: 1.07;
  transition: 0.5s ease-in-out;
}

.packing-card h2 {
  font-size: 18px;
  font-weight: url('./fonts/Lato-Bold.ttf');
  color: var(--white-color);
  padding: 20px;
  margin: 0px;
  text-transform: uppercase;
}

.packing-card-line {
  position: absolute;
  width: 85px;
  height: 3px;
  background-color: var(--primary-color);
  border-radius: 10px;
  bottom: -20px;
  z-index: 1;
}

.active-packing-card {
  scale: 1.07 !important;
}

.packing-card-shadow {
  position: absolute;
  background-color: rgba(1, 45, 68, 0.6);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.packing-card-1 {
  background-image: url('./assets/images/Machine-1.png');
}


.packing-card-2 {
  background-image: url('./assets/images/Machine-2.png');
}


.packing-card-3 {
  background-image: url('./assets/images/Machine-3.png');
}


.packing-card-4 {
  background-image: url('./assets/images/Machine-4.png');
}


.packing-card-5 {
  background-image: url('./assets/images/Machine-5.png');
}


.packing-card-6 {
  background-image: url('./assets/images/Machine-6.png');
}


.packing-card-7 {
  background-image: url('./assets/images/Machine-7.png');
}


/* Quality Policy section  */

.quality-policy-section {
  background-image: url('./assets/images/Quality-Policy-banner.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 240px;
  max-width: screen;
}

.revo-machine-heading {
  font-size: 55px;
  text-transform: uppercase;
  -webkit-text-stroke: 0.5px var(--white-color);
  color: transparent;
  letter-spacing: 5px;
}

.revo-machine-description {
  font-size: 20px;
  text-align: center;
}

.packing-description-2 {
  padding-right: 40px;
}

/* Quality Policy section  */


/* Carousel  */
.carousel {
  margin: 0px 10px;
  height: 379px;
  background-color: var(--gray3-color);
  border-radius: 8px;
  border: 2px solid var(--gray-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel-image {
  transform: translateY(30px);
  background-size: contain;
  margin-top: 15px;
  height: 275px;
  width: 262px;
  margin-top: 10px;
}

.carousel-before {
  opacity: 0;
  background-color: var(--primary-color);
  transform: translateY(30px);
  margin-top: 20px;
  position: absolute;
  bottom: 0;
  padding: 10px;
  cursor: pointer;
}

.carousel-before h2 {
  color: var(--white-color);
  text-transform: uppercase;
  font-size: 18px;
  margin: 0px;
}

.icon-left-arrow {
  width: 30px;
  height: 30px;
}

.carousel:hover .carousel-image {
  transform: translateY(0);
  transition: 0.5s ease-in-out;

}

.carousel:hover .carousel-before {
  opacity: 1;
  transform: translateY(0);
  transition: 0.5s ease-in-out;
}

.custom-navigation {
  background: var(--primary-color);
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  z-index: 10;
  text-align: center;
  padding: 18px;
}

.prev {
  margin-right: -35px;
  color: var(--white-color);
}

.next {
  margin-left: -35px;
  color: var(--white-color);
}






/* Home Page Components Styles */
.home_section_heading {
  width: auto;
}

.heading-content {
  white-space: pre-line;
  /* This preserves newlines */
  overflow-wrap: break-word;
  /* Ensures text wraps within the container */
}

.home_section_heading h1 {
  font-size: 16px;
  text-transform: uppercase;
  color: var(--secondary-color);
  margin: 0;
}

.home_section_heading h2 {
  font-size: 32px;
  text-transform: uppercase;
  color: var(--primary-color);
  margin: 13px 0px 0px 0px;
}

.company_intro p {
  font-family: url('./fonts/Lato-Regular.ttf');
  font-size: 16px;
}

.home_page_btn {
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  color: var(--white-color);
  font-size: 16px;
  text-transform: uppercase;
  padding: 0px 40px;
  border-radius: 8px;
  display: inline-flex;
  transition: background-color 0.4s ease, color 0.4s ease;
  justify-content: center;
  height: 47px;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.home_page_btn a {
  transition: transform 0.4s ease, font-weight 0.4s ease;
  display: inline-block;
  /* Ensure transform applies correctly */
  transform-origin: center;
  /* Scale from the center */
}

.home_page_btn:hover {
  background-color: var(--white-color);
  color: var(--primary-color);
}

.home_page_btn:hover a {
  transform: scale(1.1);
  /* Scale up the text */
  font-weight: bold;
  /* Optional: add font-weight change */
}



.home_page_btn:hover a {
  font-weight: 600;
  transform: scale(1.05);
}


/* Home Page Components Styles */


.inner-contact-section {
  background-color: var(--primary-color);
}

.home-accordion-bg {
  background-image: url('./assets/images/accordion_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  position: static;
  background-attachment: fixed;
}


/* Remove default Bootstrap Accordion styles */
.custom-accordion-item {
  margin-top: 30px;
  border: 1px solid var(--primary-color) !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.custom-accordion-header {
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bold;
  color: #000;
}

/* Remove default Bootstrap styles for Accordion body */
.custom-accordion-body {
  color: #000;
  padding: 20px;
  border: none;
  border-top: 1px solid var(--gray-color);
}

/* Remove default focus and active states */
.accordion-button {
  box-shadow: none !important;
  border-radius: 10px !important;
}

.accordion-button::after {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
  transform: scale(1.2);
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
  color: inherit;
}

.accordion-button:focus {
  box-shadow: none;
}

/* Container style */
.accordion-container {}


/* subscribe */
.subscribe-input {
  padding-left: 20px;
  padding-right: 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 100%;
  border: 1px solid var(--gray-color) !important;
  font-size: 18px;
}

.home-about-image {
  height: 510px;
  background-size: contain;
}




/* HOME PAGE STYLES  */



/* packing start here */

.packing-banner {
  background-image: url('./assets/images/contact-overlay.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 255px;
  display: flex;
  position: relative;
  color: white;
  background-repeat: no-repeat;
}

.card-image {
  height: 132px;
  width: 132px;
  border-radius: 10px;
  border: 3px solid var(--primary-color);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transform: transition 0.3s ease;
  filter: drop-shadow(0px 4px 50px rgba(32, 68, 142, 0.15));
  margin-top: 25px;
}

.card-image:hover {
  scale: 1.05;
}

.machines-image {
  /* width: 100%;
  height: 100%; */
  transition: transform 0.3s ease;
}

.machines-image-padding-right {
  padding-right: 35px;
}

.machines-image-padding-left {
  padding-left: 35px;
}

.machines-image:hover {
  transform: scale(1.1);
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.2));
}

.packing-inner-container-left {
  padding-left: 20px;
}

.packing-description {
  font-size: 16px;
  text-align: justify;
  line-height: 1.4;
  margin: 0px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin-top: 20px;
}

.packing-category-heading {
  color: var(--secondary-color);
  font-weight: bold;
  padding-top: 27px;
}


/* packing page end */


/* vertical packing */
.vertical-packing-banner {
  background-image: url('./assets/images/packing_banner.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 255px;
  display: flex;
  position: relative;
  color: white;
  background-repeat: no-repeat;
}

.packaging-card-container {
  /* margin-top: 30px; */
}

.table-container {
  overflow: hidden;
  margin-top: 30px;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
}

.table-heading {
  background-color: var(--primary-color) !important;
  color: #fff;
  font-weight: bold;
}

.table thead th {
  background-color: var(--primary-color) !important;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  vertical-align: middle;
  text-align: center;
  font-weight: 500;
}

.table td {
  /* font-size: 16px; */
  vertical-align: middle;
  text-align: center;
  border: 1px solid var(--gray-color);
  background-color: var(--white-color) !important;
  color: var(--black-color);
}

.table td,
.table th {
  border-radius: 0;
}

.contact-info-section {
  background-color: var(--primary-color);
  margin-top: 100px;
  position: relative;
  height: 240px;
}

.contact-icon-box {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white-color);
  border: 2px solid var(--primary-color);
  border-radius: 100px;
  padding: 25px 20px;
  height: 120px;
  width: 120px;
}

.contact-icon {
  height: 45px;
  width: 45px;
}

.contact-sub-title {
  font-size: 18px;
}

.contact-info-section-child {
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
}

.contact-title {
  font-size: 28px;
}

.contact-info {
  /* font-size: 16px; */
}


/* vertical packing end */

.divider-comp {
  /* max-width: 1440px; */
}



.powder-images {
  position: relative;
  /* This is important for positioning the overlay */
  height: 512px;
  width: 577px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
}

.powder-images-child {
  height: 160px;
  width: 218px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
}

.powder-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  opacity: 0;
  z-index: 99;
  transition: opacity 0.3s ease;
}

.powder-image-overlay-visible {
  opacity: 1;
  transition: opacity 1s ease;
}

.powder-image-overlay-children {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  opacity: 0;
  z-index: 99;
  transition: opacity 0.3s ease;
}

.powder-image-overlay-visible-children {
  opacity: 1;
  transition: opacity 1s ease;
}

.powder-packing-container {
  margin-top: 90px;
}

.powder-packing-container-first {
  /* margin-top: 35px; */
}

.quality-line {
  height: 3px;
  width: 280px;
  background-color: white;
}


@media(max-width:428px) {
  /* .home_page_btn {
    font-size: 6px;
    padding: 12px 4px;
    width: 120px;
  } */


  .custom-horizontal-line {
    height: 5px;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), #FF4500);
    border: none;
    margin: 0;
    flex: 0.5
  }


  .custom-horizontal-line_2 {
    height: 5px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #FF4500);
    border: none;
    flex: 0.5;
    margin-right: 0px;
  }
}



.text-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 8px;

}

.text {
  color: var(--white-color);
  position: relative;
  font-size: 2rem;
  /* Adjust as needed */
  padding: 0 10px;
  /* Add padding to account for the background */
  margin: 0;
  z-index: 1;
  opacity: 0;
  /* Hide the text by default */
  transform: translateY(-20px);
  /* Move text out of view by default */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

/* Background adjustment */
.background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--secondary-color);
  z-index: 0;
  width: 0;
  transition: width 0.5s ease-in-out, background-color 0.5s ease;
  /* Smooth animation */
  border-radius: 8px;
}

/* Bounce and translate animation */
@keyframes bounceIn {
  0% {
    transform: translateY(-30px);
    /* Start above */
    opacity: 0;
  }

  50% {
    transform: translateY(10px);
    /* Bounce down */
  }

  70% {
    transform: translateY(-5px);
    /* Bounce up slightly */
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.text-enter {
  animation: bounceIn 0.8s ease-out forwards;
}


/* mobile header */

.main-logo-mobile {
  width: 100%;
  height: auto;
  max-height: 60px;
}

.mobile-header {
  display: none;
  background-color: var(--white-color);
  color: white;
  padding: 1rem;
}

.mobile-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.overlay.show {
  opacity: 1;
}

.hamburger-icon {
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--primary-color);
  padding-right: 20px;
}

.mobile-drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 75%;
  height: 100%;
  background-color: var(--primary-color);
  color: white;
  padding: 1rem;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.mobile-drawer.open {
  transform: translateX(0);
}

.mobile-drawer.close {
  transform: translateX(100%);
}

.mobile-nav-menu {
  list-style: none;
  padding: 0;
}

.mobile-nav-menu>li {
  margin-bottom: 1rem;
}

.mobile-nav-menu>li>a {
  color: white;
  text-decoration: none;
}

.mobile-dropdown-menu>li>a {
  color: var(--gray-color) !important;
  text-decoration: none;
}

.angle-right {
  font-size: 1rem;
}

.mobile-dropdown-menu {
  margin-left: 0.8rem;
  list-style: none;
  padding-left: 0px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.mobile-dropdown-menu.open {
  max-height: 500px;

}

.mobile-dropdown-menu.close {
  max-height: 0;
}

.angle-right {
  transition: transform 0.3s ease-in-out;
}

.angle-right.rotate {
  transform: rotate(90deg);
}

.page-link {
  text-decoration: none;
}

.drawer-top {
  color: var(--secondary-color);
  font-weight: bold;
}

.close-icon-drawer {
  color: var(--secondary-color);
  font-size: 1.5rem;
}

@keyframes slideIn {
  to {
    transform: translateX(0);
  }
}